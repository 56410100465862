@font-face {
    font-family: "Agustina Regular";
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local("Agustina Regular"),
    url("./assests/fonts/Agustina.woff") format("woff");
}

@font-face {
    font-family: "Montserrat";
    font-display: swap;
    src: local("Montserrat"),
    url("./assests/fonts/Montserrat-Regular.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Bold";
    font-display: swap;

    src: local("Google Sans Bold"),
    url("./assests/fonts/GoogleSans-Bold.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Bold Italic";
    font-display: swap;

    src: local("Google Sans Bold Italic"),
    url("./assests/fonts/GoogleSans-BoldItalic.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Italic";
    font-display: swap;

    src: local("Google Sans Italic"),
    url("./assests/fonts/GoogleSans-Italic.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Medium";
    font-display: swap;

    src: local("Google Sans Medium"),
    url("./assests/fonts/GoogleSans-Medium.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Medium Italic";
    font-display: swap;

    src: local("Google Sans Medium Italic"),
    url("./assests/fonts/GoogleSans-MediumItalic.ttf") format("woff");
}

@font-face {
    font-family: "Google Sans Regular";
    font-display: swap;

    src: local("Google Sans Regular"),
    url("./assests/fonts/GoogleSans-Regular.ttf") format("woff");
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    margin: 0;
    font: 19px / 23px Montserrat, "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Media Query */
@media (max-width: 1380px) {
    html,
    body {
        font-size: 16px;
        line-height: normal;
    }
}
