.video-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

}

.video {
    flex: 0 0 100%;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 8px;

}

.video:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.video img {
    width: 100%;
    object-fit: scale-down;
}

.video h3 {
    margin: 20px 0;
    font-size: 16px;
    text-align: center;
    color: rgb(90, 99, 119);
    font-family: "Google Sans Regular";
}

.youtube-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.youtube-header {
    font-size: 32px;
    font-weight: 400;
    font-family: "Google Sans Regular";
    line-height: normal;
    text-align: center;
    margin-top: 100px;
}

/* Medium devices (tablets, less than 992px) */
@media only screen and (min-width: 768px) {
    .video {
        flex: 0 0 40%;
    }
    .youtube-header{
        font-size: 40px;
    }
}

/* Large devices (desktops, less than 1200px) */
@media only screen and (min-width: 992px) {
    .video {
        flex: 0 0 20%;
    }
    .youtube-header{
        font-size: 56px;
    }
}
