.faq-main {
    width: 100%;
}

.basic-faq {
    margin-left: 5%;
    margin-right: 5%;
}

.faq-heading-div {
    display: flex;
}

.faq-heading-div > * {
    flex: 1;
}

.faq-heading-img-div {
    align-items: center;
    justify-content: center;
}

.faq-heading-text-div {
    text-align: center;
}

.faq-heading-img-div > * {
    max-width: 100%;
    height: auto;
}

.faq-heading-text {
    font-size: 56px;
    font-weight: 400;
    font-family: "Google Sans Medium";
    line-height: 1.1;
    text-align: center;
    margin-top: 80px;
}

.faq-heading-sub-text {
    font-size: 30px;
    font-family: "Google Sans Regular";
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.625;
}

.faq-header-detail-text {
    font-size: 20px;
    line-height: 30px;
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    font-family: "Google Sans Medium";
}

@media (max-width: 1380px) {
    .faq-heading-text {
        font-size: 50px;
        margin-top: 80px;
    }
    .faq-heading-sub-text {
        font-size: 25px;
        line-height: 1.625;
    }
}

@media (max-width: 768px) {
    .faq-heading-text {
        font-size: 30px;
        margin-top: 20px;
    }

    .faq-heading-sub-text {
        font-size: 20px;
        line-height: 1.625;
    }

    .faq-heading-div {
        flex-direction: column;
    }

    .faq-header-detail-text {
        font-size: 16px;
        margin-right: 0px;
        line-height: normal;
        text-align: center;
    }
}
